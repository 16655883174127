export default function SobreMi() {
  return (
    <section id="sobre-mi" className="py-16 bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">Sobre Mí</h2>
        <p className="text-lg max-w-3xl mx-auto">
          Con más de 30 años de experiencia en pádel, he dedicado mi vida a dominar y enseñar este increíble deporte. 
          Desde jugar profesionalmente hasta entrenar a jugadores de alto ranking, mi trayectoria en el pádel ha sido extraordinaria.
          Mi pasión es ayudar a jugadores de todos los niveles a alcanzar su máximo potencial en la pista.
        </p>
      </div>
    </section>
  )
}