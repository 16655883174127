export default function Logros() {
  return (
    <section id="logros" className="py-16 bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">Logros</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <div className="bg-gray-700 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4">Como Jugador</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Número 1 en el Ranking de Pádel de Tenerife (2001, 2002)</li>
              <li>Campeón del Open Internacional Sabina (2003, 2006)</li>
              <li>Finalista en múltiples torneos nacionales e internacionales</li>
            </ul>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4">Como Entrenador</h3>
            <ul className="list-disc list-inside space-y-2">
              <li>Entrenador de jugadores top en el Ranking Absoluto Canario</li>
              <li>Llevó al Equipo de Veteranos de Canarias al Campeonato de España de 2ª Categoría (2021)</li>
              <li>Formador de numerosos jugadores profesionales</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}