export default function Footer() {
  return (
    <footer className="bg-gray-900 py-8">
      <div className="container mx-auto px-4 text-center">
        <p>&copy; 2024 Javi Silva Pádel. Todos los derechos reservados.</p>
        <div className="mt-4">
          <button className="text-gray-400 hover:text-white mx-2 transition-colors duration-300">Política de Privacidad</button>
          <button className="text-gray-400 hover:text-white mx-2 transition-colors duration-300">Términos de Servicio</button>
        </div>
      </div>
    </footer>
  )
}