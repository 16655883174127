import { useState } from 'react'
import { Menu, X } from 'lucide-react'
import { useActiveSection } from './useActiveSection'

const navItems = ['inicio', 'sobre-mi', 'experiencia', 'logros', 'contacto']

export default function Header() {
  const [menuAbierto, setMenuAbierto] = useState(false)
  const activeSection = useActiveSection(navItems)

  const toggleMenu = () => setMenuAbierto(!menuAbierto)

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-gray-900 bg-opacity-90">
      <nav className="container mx-auto px-4 py-4 flex justify-between items-center">
        <img src="/Solo_letras.png" alt="Logo de Javi Silva Pádel" className="w-24 h-auto" />
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {menuAbierto ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        <ul className={`md:flex space-x-6 ${menuAbierto ? 'block absolute top-full left-0 right-0 bg-gray-900 p-4' : 'hidden'} md:relative md:bg-transparent md:p-0`}>
          {navItems.map((item) => (
            <li key={item}>
              <a
                href={`#${item}`}
                className={`hover:text-blue-400 transition-colors duration-300 ${
                  activeSection === item ? 'text-blue-400 font-bold' : 'text-white'
                }`}
                onClick={() => setMenuAbierto(false)}
              >
                {item.charAt(0).toUpperCase() + item.slice(1).replace('-', ' ')}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}