import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronLeft, ChevronRight } from 'lucide-react'

const imagenesFondo = [
  {
    src: "/ComfyUI_00515_sin_fondo_1.png",
    alt: "Javi Silva retrato",
  },
  {
    src: "/ComfyUI_00498_.png",
    alt: "Javi Silva retrato",
  },
  {
    src: "/ComfyUI_00507_.png",
    alt: "Javi Silva entrenando",
  },
  {
    src: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/page5_img15-mPOOK1fp8RvJjdCwonw7rp1vNgjKO8.jpeg",
    alt: "Javi Silva en la cancha",
  },
  {
    src: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/page5_img17-zZ4YtfYbIyG6Az7GRYzQSakMLiV37I.jpeg",
    alt: "Javi Silva dando instrucciones",
  },
  {
    src: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/page5_img37-yQNTFADKmTDJQTOKfoAAwCgSA7DLvs.jpeg",
    alt: "Javi Silva con un trofeo",
  },
  {
    src: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/page8_img38-RyxMiXjg2ymEo3UQMvItgzUms0ZHxP.jpeg",
    alt: "Javi Silva con su equipo",
  },
  {
    src: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/page9_img24-OX7H4HNjartyKBHye1cW61ICEyfOyp.jpeg",
    alt: "Javi Silva en un evento",
  }
]

// Constantes para ajustar las posiciones
const CONTAINER_POSITION = 20 // Ajusta este valor para mover todo el contenedor horizontalmente
const LOGO_POSITION = -150 // Ajusta este valor para mover el logo horizontalmente
const TEXT_POSITION = 0 // Ajusta este valor para mover el texto horizontalmente
const BUTTON_POSITION = -150 // Ajusta este valor para mover el botón horizontalmente

export default function Inicio() {
  const [indiceImagenActual, setIndiceImagenActual] = useState(0)

  useEffect(() => {
    const intervalo = setInterval(() => {
      setIndiceImagenActual((prevIndex) => (prevIndex + 1) % imagenesFondo.length)
    }, 5000)
    return () => clearInterval(intervalo)
  }, [])

  const nextImage = () => {
    setIndiceImagenActual((prevIndex) => (prevIndex + 1) % imagenesFondo.length)
  }

  const prevImage = () => {
    setIndiceImagenActual((prevIndex) => (prevIndex - 1 + imagenesFondo.length) % imagenesFondo.length)
  }

  return (
    <section id="inicio" className="relative h-screen flex items-start justify-start overflow-hidden">
      <AnimatePresence initial={false}>
        <motion.div
          key={indiceImagenActual}
          className="absolute inset-0 bg-black"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <div 
            className="w-full h-full bg-center bg-no-repeat bg-contain"
            style={{
              backgroundImage: `url(${imagenesFondo[indiceImagenActual].src})`,
            }}
          />
        </motion.div>
      </AnimatePresence>
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      <div 
        className="absolute bottom-0 left-0 z-10 p-4 sm:p-6 md:p-8"
        style={{ marginLeft: `${CONTAINER_POSITION}px` }}
      >
        <div className="flex flex-col items-center">
          <img 
            src="/Solo_pala.png" 
            alt="Logo de Javi Silva Pádel" 
            className="w-24 sm:w-32 md:w-40 h-auto mb-2 sm:mb-3" 
            style={{ marginLeft: `${LOGO_POSITION}px` }}
          />
          <p 
            className="text-white text-lg sm:text-xl md:text-2xl mb-2 sm:mb-3 text-center"
            style={{ marginLeft: `${TEXT_POSITION}px` }}
          >
            Entrenador Profesional de Pádel
          </p>
          <a 
            href="#contacto" 
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300 text-sm sm:text-base"
            style={{ marginLeft: `${BUTTON_POSITION}px` }}
          >
            Contáctame
          </a>
        </div>
      </div>
      <button 
        onClick={prevImage} 
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
      >
        <ChevronLeft size={24} />
      </button>
      <button 
        onClick={nextImage} 
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
      >
        <ChevronRight size={24} />
      </button>
    </section>
  )
}