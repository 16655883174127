export default function Experiencia() {
  return (
    <section id="experiencia" className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">Experiencia</h2>
        <ul className="space-y-4 max-w-3xl mx-auto">
          <li className="flex items-start">
            <span className="bg-blue-600 rounded-full p-2 mr-4"></span>
            <div>
              <h3 className="text-xl font-semibold">Jugador Profesional de Pádel</h3>
              <p>Desde 1990 - Participación en torneos nacionales e internacionales</p>
            </div>
          </li>
          <li className="flex items-start">
            <span className="bg-blue-600 rounded-full p-2 mr-4"></span>
            <div>
              <h3 className="text-xl font-semibold">Entrenador Principal</h3>
              <p>Club de Tenis de Tenerife (2001-presente)</p>
            </div>
          </li>
          <li className="flex items-start">
            <span className="bg-blue-600 rounded-full p-2 mr-4"></span>
            <div>
              <h3 className="text-xl font-semibold">Seleccionador de la Federación Canaria de Pádel</h3>
              <p>2015-2022</p>
            </div>
          </li>
          <li className="flex items-start">
            <span className="bg-blue-600 rounded-full p-2 mr-4"></span>
            <div>
              <h3 className="text-xl font-semibold">Instructor de Cursos</h3>
              <p>Técnico Regional y Monitor de Pádel Nivel I</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}