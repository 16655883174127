import Header from './Header'
import Inicio from './Inicio'
import SobreMi from './SobreMi'
import Experiencia from './Experiencia'
import Logros from './Logros'
import Contacto from './Contacto'
import Footer from './Footer'

export default function JaviSilvaPadel() {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      <main>
        <Inicio />
        <SobreMi />
        <Experiencia />
        <Logros />
        <Contacto />
      </main>
      <Footer />
    </div>
  )
}