import { Mail, Phone, Facebook, Instagram } from 'lucide-react'

export default function Contacto() {
  return (
    <section id="contacto" className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center">Contáctame</h2>
        <div className="max-w-md mx-auto">
          <p className="text-center mb-6">¿Listo para llevar tu juego de pádel al siguiente nivel? Ponte en contacto conmigo:</p>
          <ul className="space-y-4">
            <li className="flex items-center">
              <Mail className="mr-4 text-blue-400" />
              <span>javiersilva65@hotmail.com</span>
            </li>
            <li className="flex items-center">
              <Phone className="mr-4 text-blue-400" />
              <span>(+34) 647819692</span>
            </li>
            <li className="flex items-center">
              <Facebook className="mr-4 text-blue-400" />
              <a href="https://www.facebook.com/javier.silva.501" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition-colors duration-300">
                Facebook
              </a>
            </li>
            <li className="flex items-center">
              <Instagram className="mr-4 text-blue-400" />
              <a href="https://www.instagram.com/javisilvapedroso" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400 transition-colors duration-300">
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}